import iframeWrapper from 'sdk/IFrameWrapper';
import { SDKState, selectedBusinessIdSelector, startPageSelector } from 'sdk/reducers';
import {
    UserStatus,
    UserStatuses,
    resetNavigationHistory,
    updateUserStatus,
} from 'sdk/reducers/page';
import NavigationService from 'sdk/services/navigation';

/**
 * Thunk called to log a user in
 */
export const loginThunk =
    (tokenOrApiKey: string) => async (dispatch: any, getState: () => SDKState) => {
        dispatch(updateUserStatus(UserStatuses.LOGGING_IN as UserStatus));
        const state = getState();
        const page = startPageSelector(state) ?? '/';
        const selectedBusinessId = selectedBusinessIdSelector(state);
        dispatch(resetNavigationHistory());

        const loginUrl = NavigationService.generateLoginURL(
            tokenOrApiKey,
            page,
            selectedBusinessId,
        );
        iframeWrapper.updateIframeUrl(loginUrl);
    };
